import ErrorBanner from "components/Banners";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@jobber/components/Spinner";

function DocusignCallback() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/json');
        if (code) requestHeaders.set('X-DOCUSIGN-CODE', code);
        if (state) requestHeaders.set('X-INTEGRATION-STATE', state);

        fetch('/api/v1/docusign/authorize', {
            method: "POST",
            headers: requestHeaders
        })
            .then(async res => {
                if (res.status === 200) return Promise.resolve();
                else return Promise.reject(await res.json());
            })
            .then(data => navigate('/step-2'))
            .catch(e => ErrorBanner(e.message));
    }, [navigate]);

    return (<div className="item-center">
        <Spinner size="base" aria-label="loading" />
    </div>);
}


export default DocusignCallback;
