import ErrorBanner from "components/Banners";
import { showToast } from "@jobber/components/Toast";
import { Dispatch, SetStateAction } from "react";

export function getTemplate(
    headers: HeadersInit,
    fnSet: Dispatch<SetStateAction<any>>,
    fnActive: Dispatch<React.SetStateAction<any>>,
    successMessage?: string,
) {
    return fetch('/api/v1/docusign/templates', { headers })
        .then(res => res.json())
        .then(data => {
            const { selected, templates } = data;

            if (Array.isArray(templates)) {
                templates.unshift({
                    'id': '',
                    'name': 'Select a template'
                });
                fnSet(templates);
                fnActive(selected ? selected : templates[0].id)

                if (successMessage !== undefined) {
                    showToast({
                        message: successMessage,
                        variation: "success"
                    })
                }

                return true;
            }

            fnSet([]);
            return Promise.reject(data);

        })
        .catch(e => ErrorBanner(e.message));
}

export function refreshTemplates(
    headers: HeadersInit,
    fnSet: Dispatch<SetStateAction<any>>,
    fnActive: Dispatch<React.SetStateAction<any>>,
) {
    getTemplate(headers, fnSet, fnActive, "Templates refreshed");
}

export function getTriggers(
    headers: HeadersInit,
    fnSet: Dispatch<SetStateAction<any>>,
    fnActive: Dispatch<React.SetStateAction<any>>
) {
    fetch('/api/v1/jobber/triggers', { headers })
        .then(res => res.json())
        .then(data => {
            const { selected, triggers } = data;

            if (Array.isArray(triggers)) {
                fnSet(triggers);
                fnActive(selected ? selected : triggers[0].id);
            } else {
                fnSet([]);
                return Promise.reject(data);
            }
        })
        .catch(e => ErrorBanner(e.message));
}

export async function updateModalStatus(context: string, headers: HeadersInit) {
    fetch(`/api/v1/docusign/check/custom-tabs?context=${context}`, { method: 'POST', headers })
        .then(res => res.json())
        .catch(e =>  ErrorBanner(e.message));
}

export enum ModalContext {
    Step2 = 'step2',
    Step3 = 'step3',
    Settings = 'settings',
}

export function getModal(
    context: ModalContext,
    headers: HeadersInit,
    fnModal: Dispatch<SetStateAction<any>>,
    fnPermissions?: Dispatch<SetStateAction<boolean|undefined>>
) {    
    fetch(`/api/v1/docusign/check/custom-tabs/${context}`, { headers } )
        .then(res => res.json())
        .then(res => {
            fnModal(res.show_modal);
            if (fnPermissions) {
                fnPermissions(res.has_permissions);
            }
        })
        .catch(e => ErrorBanner(e.message));
}

export function skipModal(
    context: ModalContext,
) {
  console.debug("skipModal state", sessionStorage.getItem('state'));
  return fetch(`/api/v1/docusign/check/custom-tabs/${context}`, {
    method: 'POST',
    headers: {
      "Content-Type": 'application/json',
      'X-INTEGRATION-STATE': sessionStorage.getItem('state') || '',
    } } )
    .then(res => res.json())
    .catch(e => ErrorBanner(e.message));
}

export function getAccountName(
    headers: HeadersInit,
    fnSet: Dispatch<SetStateAction<any>>
) {
    fetch('/api/v1/docusign/account', { headers })
        .then(res => res.json())
        .then(data => {
            const { account } = data;
            if (account !== null) {
                fnSet(account.name);
            }
        })
        .catch(e => ErrorBanner('Missing Docusign account connection, try reconnecting'))
}

export function saveSettings(
    requestHeaders: HeadersInit,
    templateActive: string | boolean,
    triggerActive: string,
    triggers: { id: string; name: string }[],
    templates: { id: string; name: string }[],
    successMessage?: string,
) {
    return fetch('/api/v1/settings', {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({
            event_id: triggerActive,
            template_id: templateActive
        })
    })
        .then(async res => {
            const event = triggers.find(row => row.id === triggerActive)?.name;
            const template = templates.find(row => row.id === templateActive)?.name;

            if (event) sessionStorage.setItem('event', event);
            if (template) sessionStorage.setItem('template', template);

            if (res.status === 200) {
                if (successMessage !== undefined) {
                    showToast({
                        message: successMessage,
                        variation: "success"
                    })
                }

                return Promise.resolve();
            }
            return Promise.reject(await res.json());
        });
}
