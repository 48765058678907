import { Banner } from "@jobber/components/Banner";
import { createRoot } from 'react-dom/client';
import config from "../../config";

export default function ErrorBanner(message: string): void {
    const div = document.createElement('div') as HTMLElement;
    const el = createRoot(div);

    el.render(<Banner type={"error"} dismissible={true} primaryAction={{
        label: "Return to Jobber.com",
        onClick:() => window.location.href=config.jobberAppUrl,
    }}>{message}
    </Banner>);

    document.getElementById('banners')?.appendChild(div)
}
