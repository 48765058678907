import React, {FC} from "react";
import {Spinner} from "@jobber/components/Spinner";

type LoadingWrapperProps = {
    condition: boolean|undefined;
    children: React.JSX.Element | React.JSX.Element[];
    spinner?: React.JSX.Element;
}

const LoadingWrapper: FC<LoadingWrapperProps> = ({
    children,
    condition,
    spinner,
}) => {
    if (condition == undefined) {
        return spinner ?? <Spinner size="base" aria-label="loading" />
    }

    return (
        <>
            {condition ? children : null}
        </>
    )
};

export default LoadingWrapper;
