import { FC } from "react";
import { Outlet } from "react-router-dom";
import styles from "./AppFrame.module.scss";
import Footer from "./Footer";
import AppLogo from "./AppLogo";
import Wrapper from "components/Wrapper/Wrapper";

type AppFrameProps = {
  logo: string;
};

const AppFrame: FC<AppFrameProps> = ({ logo }) => {
  return (
    <div className={styles.appFrame}>
      <div className={styles.scrollWrapper}>
        <div className={styles.centerWrapper}>
          <AppLogo logo={logo} />
          <div id="banners" className={styles.banners}></div>
          <div className={styles.content}>
            <Wrapper>
              {<Outlet />}
            </Wrapper>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AppFrame;
