import Title from "components/Title";
import styles from "./styles.module.scss";
import { Button } from "@jobber/components/Button";
import Radio from "components/Radio";
import { Select, Option } from "@jobber/components/Select";
import { useEffect, useState } from "react";
import { InputValidation } from "@jobber/components/InputValidation";
import { getAccountName, getModal, getTemplate, getTriggers, refreshTemplates, saveSettings, ModalContext } from "helpers";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Grid } from "@jobber/components/Grid";
import { Emphasis } from "@jobber/components/Emphasis";
import config from "config";
import ErrorBanner from "components/Banners";
import { Spinner } from "@jobber/components/Spinner";
import { Skip, Success } from "components/Modals";

function Settings() {

  const context = ModalContext.Settings;

  const [triggers, setTriggers] = useState<{ id: string; name: string }[]>([]);
  const [templates, setTemplates] = useState<{ id: string; name: string }[]>([]);
  const [templateIsValid, setTemplateIsValid] = useState<boolean>(false);
  const [requestHeaders, setRequestHeaders] = useState<HeadersInit>(new Headers());
  const [triggerActive, setTriggersActive] = useState<string>('');
  const [templateActive, setTemplateActive] = useState<string>();
  const [isLoadTemplates, setIsLoadTemplates] = useState<boolean|void>(false);
  const [accountName, setAccountName] = useState<string>();
  const [modalType, setSetModalType] = useState<string>('no_modal');

  useEffect(function () {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    const requestH: HeadersInit = new Headers();
    requestH.set('Content-Type', 'application/json');
    if (code) requestH.set('X-JOBBER-CODE', code);

    else {
      window.location.href = config.jobberAppUrl;
      return;
    }

    fetch('/api/v1/jobber/reauthorize', {
      method: "POST",
      headers: requestH
    })
      .then(res => res.json())
      .then(data => {
        // Store state regardless of redirect because we need it for modal API calls
        sessionStorage.setItem('state', data.state);

        if (data.redirect_url) {
          window.location.href = data.redirect_url;
          return;
        }

        requestH.delete('X-JOBBER-CODE');
        
        requestH.set('X-INTEGRATION-STATE', data.state);
        setRequestHeaders(requestH);

        getModal(context, requestH, setSetModalType);
        getTriggers(requestH, setTriggers, setTriggersActive);
        getTemplate(requestH, setTemplates, setTemplateActive)
          .then(status => setIsLoadTemplates(status));
        getAccountName(requestH, setAccountName);

      })
      .catch(e => ErrorBanner(e.message));

  }, [context]);

  async function save() {
    if (!templateActive) {
      setTemplateIsValid(true);
      return false;
    }
    setTemplateIsValid(false);

    await saveSettings(requestHeaders, templateActive, triggerActive, triggers, templates, "Your settings were saved")
      .catch(e => ErrorBanner(e.message));
  }

  return (
    <>
      <Title title="Settings" subTitle="" connectedAccountName={accountName} />
      <Heading level={5}>Auto-draft a Docusign agreement when Jobber</Heading>
      <Radio fn={setTriggersActive} options={triggers} active={triggerActive} />
      <div className={`${styles.width} ${styles.marginSmall} ${styles.flextRefresh}`}>
        <Heading level={5}>Use this Docusign template</Heading>
        <Button size="small" type="tertiary" onClick={() => refreshTemplates(requestHeaders, setTemplates, setTemplateActive)} icon="redo" label="Refresh" />
      </div>
      <div className={`${styles.width} ${styles.marginSmall} ${styles.flextRefresh}`}>
        {
          isLoadTemplates ?
            <Select
              value={templateActive}
              validations={{}}
              invalid={templateIsValid}
              onChange={(value: string) => {
                setTemplateIsValid(!value);
                setTemplateActive(value);
              }}>
              {templates.map((trigger, index) =>
                <Option key={index} value={trigger.id}> {trigger.name} </Option>
              )}
            </Select>
            :
            <div className="center-spinner"><Spinner size="base" aria-label="loading" /></div>
        }
        {templateIsValid && <InputValidation message="Required field" />}
      </div>
      <div className={`${styles.width} ${styles.marginBase}`}>
        <Text>
          <Emphasis variation="bold">Don’t see the Docusign template you need?</Emphasis> <a href={config.docuSignTplUrl} target="_blank" rel="noreferrer">Add template in Docusign</a>, then refresh this list.
        </Text>
      </div>

      <div className={styles.marginAuto}>
        <Grid alignItems="center">
          <Grid.Cell size={{ sm: 12, md: 7, lg: 8 }}></Grid.Cell>
          <Grid.Cell size={{ sm: 12, md: 5, lg: 4 }}>
            <div className={styles.textRight} ><Button onClick={save} label="Update Settings" /></div>
          </Grid.Cell>
        </Grid>
      </div>
      {modalType === 'need_upgrade' ? <Skip status={true} context={context}/> : (
        modalType === 'success' ? <Success status={true} context={context} /> : <></>)}
    </>
  );
}

export default Settings;
