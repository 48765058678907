import { FC } from "react";
import styles from "./styles.module.scss";
import connectImg from "assets/images/connect.svg";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { Text } from "@jobber/components/Text";
import { Grid } from "@jobber/components/Grid";
import config from "../../config";

type AppTitle = {
    title: string;
    subTitle: string;
    connectBlock?: boolean;
    connectedAccountName?: string;
};

const Title: FC<AppTitle> = ({ title, subTitle, connectedAccountName = false }) => {

    const connectEl =
        <div className={styles.connectWrapper}>
            <Grid alignItems="center">
                <Grid.Cell size={{ xs: 2 }}><img src={connectImg} alt="" /></Grid.Cell>
                <Grid.Cell size={{ xs: 8, sm: 10, md: 10 }}>
                    <Text size="large"><Emphasis variation="bold">Docusign account connected</Emphasis></Text>
                    <Text size="large"><a href={config.docuSignHomeUrl} target="_blank" rel="noreferrer">{connectedAccountName}</a></Text>
                </Grid.Cell>
            </Grid>
        </div>;

    return (<div className={styles.width100}>
        <Grid alignItems="center">
            <Grid.Cell size={{ md: 12, lg: 7 }}>
                {subTitle ? <div className={styles.subTitle}>
                    <Heading level={3} >{subTitle}</Heading>
                </div> : <></>}
                <div className={styles.title}>
                    <Heading level={1}>
                        {title}
                    </Heading>
                </div>
            </Grid.Cell>
            <Grid.Cell size={{ xs: 12, sm: 12, md: 7, lg: 5, xl: 5 }}>
                {connectedAccountName ? connectEl : <div id="none"></div>}
            </Grid.Cell>
        </Grid>
    </div>)
};

export default Title;
