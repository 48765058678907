import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <Icon name="help" color="heading" />
      <Text>
        <a href="https://help.getjobber.com/hc/en-us/articles/15504167546391-Jobber-and-DocuSign-Integration-Beta-/" rel="noreferrer" target="_blank">Need help?</a>
      </Text>
    </div>
  );
};

export default Footer;
