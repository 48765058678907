import { FC } from "react";
import styles from "./styles.module.scss";


type AppWrapper = {
    children: JSX.Element | JSX.Element[];
};

const Wrapper: FC<AppWrapper> = ({ children }) => (<div className={styles.wrapper}> {children} </div>);

export default Wrapper;
