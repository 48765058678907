import ErrorBanner from "components/Banners";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@jobber/components/Spinner";

function JobberCallback() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/json');
        if (code) requestHeaders.set('X-JOBBER-CODE', code);

        fetch('/api/v1/jobber/authorize', {
            method: "POST",
            headers: requestHeaders
        })
            .then(res => res.json())
            .then(data => {
                sessionStorage.setItem('state', data.state);
                navigate('/step-1');
            })
            .catch(e => ErrorBanner(e.message));
    }, [navigate]);

    return (<div className="item-center">
            <Spinner size="base" aria-label="loading" />
        </div>);
}


export default JobberCallback;
