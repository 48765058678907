import { FC, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import screenshot from "assets/images/step-3.png"
import styles from "./styles.module.scss";
import { Emphasis } from "@jobber/components/Emphasis";
import config from "../../config";
import { ModalContext, skipModal } from "../../helpers";

type AppSkip = {
    status: boolean
    context: ModalContext
};

const Skip: FC<AppSkip> = ({status = false, context }) => {
    const [modalOpen, setModalOpen] = useState(status);

    return (
        <>
            <Modal
                title={"Upgrade your Docusign plan to auto-fill your agreements with Jobber data"}
                open={modalOpen}
                dismissible={false}
                primaryAction={{ label: "Upgrade in Docusign", onClick: handlePrimaryAction }}
                secondaryAction={{ label: "Skip", onClick: handleSecondaryAction, variation: "work", type: "secondary" }}
                size="large"
            >
                <Content>
                    <div className={`${styles.width}`}>
                        <Text size="large">
                            The plan you currently have with Docusign does not support document custom fields, which
                            <Emphasis variation="bold"> prevents this integration from auto-filling your agreements with Jobber data like client, quote, and job details.</Emphasis>
                            <a href={config.docuSignProducts} target="_blank" rel="noreferrer"> Learn more about Docusign plans & pricing.</a>
                        </Text>
                        <img src={screenshot} className={`${styles.screenshot}`} alt="" />
                    </div>
                </Content>
            </Modal >
        </>
    );

    function handlePrimaryAction() {
        skipModal(context).then();

        setModalOpen(false);
        window.open(config.docuSignProducts, "_blank");
    }

    function handleSecondaryAction() {
        skipModal(context).then();
        
        setModalOpen(false);
    }
}

export default Skip;
