import appLogo from "assets/images/app-logo.svg";
import AppFrame from "components/AppFrame";
import Settings from "pages/Settings/Settings";
import Step1 from "pages/Step1";
import Step2 from "pages/Step2";
import Step3 from "pages/Step3";
import JobberCallback from "pages/JobberCallback";
import DocusignCallback from "pages/DocusignCallback";

import {
  Navigate,
  Outlet,
  Route,
  Routes as ReactRouterRoutes,
} from "react-router-dom";

const Routes = () => {
  return (
    <ReactRouterRoutes>
      <Route element={<ProtectedRoutes />}>
        <Route element={<AppFrame logo={appLogo} />}>
          <Route index element={<Navigate to="/step-1" />} />
          <Route path="/step-1" element={<Step1 />} />
          <Route path="/step-1" element={<Step1 />} />
          <Route path="/step-2" element={<Step2 />} />
          <Route path="/step-3" element={<Step3 />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/jobber/auth/callback" element={<JobberCallback />} />
          <Route path="/docusign/auth/callback" element={<DocusignCallback />} />
        </Route>
      </Route>
    </ReactRouterRoutes>
  );
};

const ProtectedRoutes = () => {

  return <Outlet />;
};

export default Routes;
