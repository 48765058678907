import Title from "components/Title";
import styles from "./styles.module.scss";
import Steps from "components/Steps";
import { Button } from "@jobber/components/Button";
import { useNavigate } from "react-router-dom";
import Radio from "components/Radio";
import { Select, Option } from "@jobber/components/Select";
import { InputValidation } from "@jobber/components/InputValidation";
import { useEffect, useState } from "react";
import { getModal, getTemplate, getTriggers, refreshTemplates, saveSettings, ModalContext } from "helpers";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Grid } from "@jobber/components/Grid";
import { Emphasis } from "@jobber/components/Emphasis";
import config from "../../config";
import { Spinner } from "@jobber/components/Spinner";
import { Skip, Success } from "components/Modals";
import ErrorBanner from "../../components/Banners";

function Step2() {

  const navigate = useNavigate();
  const context = ModalContext.Step2;

  const [triggers, setTriggers] = useState<{ id: string; name: string }[]>([]);
  const [templates, setTemplates] = useState<{ id: string; name: string }[]>([]);
  const [triggerActive, setTriggersActive] = useState<string>('');
  const [templateActive, setTemplateActive] = useState<string>();
  const [requestHeaders, setRequestHeaders] = useState<HeadersInit>(new Headers());
  const [templateIsValid, setTemplateIsValid] = useState<boolean>(false);
  const [isLoadTemplates, setIsLoadTemplates] = useState<boolean | void>(false);
  const [modalType, setSetModalType] = useState<string>('no_modal');

  async function save() {
    if (!templateActive) {
      setTemplateIsValid(true);
      return false;
    }
    setTemplateIsValid(false);

    saveSettings(requestHeaders, templateActive, triggerActive, triggers, templates)
      .then(() => navigate('/step-3'))
      .catch(e => ErrorBanner(e.message));
  }

  useEffect(function () {
    const requestH: HeadersInit = new Headers();
    requestH.set('Content-Type', 'application/json');

    const state = sessionStorage.getItem('state');
    if (state) requestH.set('X-INTEGRATION-STATE', state);
    setRequestHeaders(requestH);
    getModal(context, requestH, setSetModalType);
    getTriggers(requestH, setTriggers, setTriggersActive);
    getTemplate(requestH, setTemplates, setTemplateActive)
      .then(status => setIsLoadTemplates(status));

  }, [context]);

  return (
    <>
      <Title title="Customize Integration" subTitle="Let’s get you set up" />
      <div className={styles.description}>
        <Text size="large">Select when you would like a Docusign agreement automatically drafted</Text>
      </div>
      <Heading level={5}>Auto-draft a Docusign agreement when Jobber</Heading>
      <Radio fn={setTriggersActive} options={triggers} active={triggerActive} />
      <div className={`${styles.width} ${styles.marginSmall} ${styles.flextRefresh}`}>
        <Heading level={5}>Use this Docusign template</Heading>
        <Button size="small" type="tertiary" onClick={() => refreshTemplates(requestHeaders, setTemplates, setTemplateActive)} icon="redo" label="Refresh" />
      </div>
      <div className={`${styles.width} ${styles.marginSmall}`}>
        {
          isLoadTemplates ?
            <Select
              value={templateActive}
              validations={{}}
              invalid={templateIsValid}
              onChange={(value: string) => {
                setTemplateIsValid(!value);
                setTemplateActive(value);
              }}>
              {templates.map((trigger, index) =>
                <Option key={index} value={trigger.id}> {trigger.name} </Option>
              )}
            </Select>
            :
            <div className="center-spinner"><Spinner size="base" aria-label="loading" /></div>
        }

        {templateIsValid && <InputValidation message="Required field" />}
      </div>
      <div className={`${styles.width} ${styles.marginBase}`}>
        <Text>
          <Emphasis variation="bold">Don’t see the Docusign template you need? </Emphasis>
          <a href={config.docuSignTplUrl} target="_blank" rel="noreferrer">Add template in Docusign</a>, then refresh this list.
        </Text>
      </div>
      <div className={styles.marginLarge}>
        <Text>You can change these settings later.</Text>
      </div>

      <div className={styles.marginAuto}>
        <Grid alignItems="center">
          <Grid.Cell size={{ sm: 12, md: 7, lg: 8 }}>
            <Steps step={2} />
          </Grid.Cell>
          <Grid.Cell size={{ sm: 12, md: 5, lg: 4 }}>
            <div className={styles.textRight} ><Button onClick={save} label="Continue" /></div>
          </Grid.Cell>
        </Grid>
      </div>
      {modalType === 'need_upgrade' ? <Skip status={true} context={context}/> : (
        modalType === 'success' ? <Success status={true} context={context}/> : <></>)}
    </>
  );
}

export default Step2;
