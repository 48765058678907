import { FC, useState } from "react";
import styles from "./styles.module.scss";
import { Content } from "@jobber/components/Content";
import { RadioGroup, RadioOption } from "@jobber/components/RadioGroup";
import { Heading } from "@jobber/components/Heading";
import { Spinner } from "@jobber/components/Spinner";

type AppRadio = {
    options: {
        id: string,
        name: string
    }[];
    fn: Function,
    active: string
};

const Radio: FC<AppRadio> = ({ options, fn, active }) => {
    const [quotes, setQuotes] = useState<string>('');

    function change(value: any) {
        fn(value);
        setQuotes(value);
    }

    return (<div className={styles.radioCards}>
        {options.length > 0 ?
            <RadioGroup onChange={change} value={quotes ? quotes : (active ? active : options[0].id)} ariaLabel="Companies">
                {options.map((option, index) => <RadioOption key={index} value={option.id}>
                    <Content spacing="small">
                        <Heading level={5}>
                            {option.name}
                        </Heading>
                    </Content>
                </RadioOption>)}
            </RadioGroup>
            :
            <div className="center-spinner"><Spinner size="base" aria-label="loading" /></div>
        }

    </div>)

};

export default Radio;
