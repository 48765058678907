import Title from "components/Title";
import styles from "./styles.module.scss";
import Steps from "components/Steps";
import screenshot from "assets/images/step-3.png"
import { Button } from "@jobber/components/Button";
import { useEffect, useState } from "react";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import config from "../../config";
import { Grid } from "@jobber/components/Grid";
import ErrorBanner from "components/Banners/ErrorBanner";
import { Skip, Success } from "components/Modals";
import { getModal, ModalContext } from "helpers";
import { Emphasis } from "@jobber/components/Emphasis";
import LoadingWrapper from "../../components/LoadingWrapper";

function Step3() {
  const context = ModalContext.Step3;

  const [template, setTemplate] = useState<string>();
  const [event, setEvent] = useState<string>();
  const [docuSignUrl, setDocuSignUrl] = useState<string>('');
  const [modalType, setSetModalType] = useState<string>('no_modal');
  const [hasPermissions, setHasPermissions] = useState<boolean>();

  useEffect(function () {
    let state = sessionStorage.getItem('state');

    const requestH: HeadersInit = new Headers();
    requestH.set('Content-Type', 'application/json');
    if (state) requestH.set('X-INTEGRATION-STATE', state);

    getModal(context, requestH, setSetModalType, setHasPermissions);

    fetch('/api/v1/docusign/template_url', { headers: requestH })
      .then(res => {
        if (res.status !== 200) return Promise.reject(res.json());
        return res.json();
      })
      .then(data => setDocuSignUrl(data.template_url))
      .catch(e => ErrorBanner(e.message));

    const sessionEvent = sessionStorage.getItem('event');
    const sessionTemplate = sessionStorage.getItem('template');

    if (sessionEvent) setEvent(sessionEvent);
    if (sessionTemplate) setTemplate(sessionTemplate);

  }, [context]);

  return (
    <>
      <Title title="Docusign Integration is ready to go!" subTitle="You’re all set" />
      <LoadingWrapper condition={hasPermissions != undefined}>
        <Text size="large">
          <MainText condition={hasPermissions == true} event={event?.toLowerCase() || ''} template={template || ''} />
        </Text>
      </LoadingWrapper>

      <div className={styles.marginLarge}>
        <LoadingWrapper condition={hasPermissions}>
          <NextStep />
        </LoadingWrapper>
      </div>

      <div className={styles.marginAuto}>
        <Grid alignItems="center">
          <Grid.Cell size={{ sm: 12, md: 4, lg: 7 }}>
            <Steps step={3} />
          </Grid.Cell>
          <Grid.Cell size={{ sm: 12, md: 8, lg: 5 }}>
            <a rel="noreferrer" target="_blank" href={docuSignUrl}>
              <Button label="Go to Docusign" />
            </a>
            <a href={config.jobberAppUrl}>
              <Button label="Continue to Jobber" type="secondary" />
            </a>
          </Grid.Cell>
        </Grid>
      </div>
      {modalType === 'need_upgrade' ? <Skip status={true} context={context}/> : (
        modalType === 'success' ? <Success status={true} context={context} /> : <></>)}
    </>
  );
}

function NextStep() {
  return (<>
    <Heading level={3}>Next Step:</Heading>

    <div className={`${styles.marginSmall} ${styles.width}`}>
      <Emphasis variation="bold">We created new custom fields in your Docusign account. </Emphasis> Drag and drop them to your Docusign template where you need data from Jobber auto-filled.
    </div>
    <img src={screenshot} className={`${styles.screenshot}`} alt="" />
  </>);
}

function MainText({condition, event, template}: {condition: boolean, event: string, template: string}) {
  if (condition) {
    return <Text size="large">
      When {event} in Jobber, we will automatically draft a new {template} in Docusign.
    </Text>
  } else {
    return <Text size="large">
      When {event} in Jobber, we will automatically draft a new {template} in Docusign with your Jobber client names, email, and company auto-synced.
    </Text>
  }
}

export default Step3;
