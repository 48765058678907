import { FC, useEffect, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import screenshot from "assets/images/step-3.png"
import styles from "./styles.module.scss";
import { Emphasis } from "@jobber/components/Emphasis";
import { Heading } from "@jobber/components/Heading";
import ErrorBanner from "components/Banners/ErrorBanner";
import { ModalContext, skipModal } from "../../helpers";

type AppSuccess = {
    status: boolean
    context: ModalContext
};

const Success: FC<AppSuccess> = ({ status, context }) => {
    const [modalOpen, setModalOpen] = useState(status);
    const [docuSignUrl, setDocuSignUrl] = useState<string>();

    useEffect(function () {
        const requestH: HeadersInit = new Headers();
        requestH.set('Content-Type', 'application/json');

        const state = sessionStorage.getItem('state');
        if (state) requestH.set('X-INTEGRATION-STATE', state);

        fetch('/api/v1/docusign/template_url', { headers: requestH })
            .then(res => {
                if (res.status !== 200) return Promise.reject(res.json());
                return res.json();
            })
            .then(data => setDocuSignUrl(data.template_url))
            .catch(e => ErrorBanner(e.message));
    });

    return (
        <>
            <Modal
                title={"Auto-fill your agreements with Jobber data"}
                open={modalOpen}
                dismissible={false}
                primaryAction={{ label: "Go to Docusign", onClick: handlePrimaryAction }}
                secondaryAction={{ label: "Got it", onClick: handleSecondaryAction, variation: "work", type: "secondary" }}
                size="large"
            >
                <Content>
                    <div className={`${styles.width}`}>
                        <Text size="large">
                            The plan you currently have with Docusign supports document custom fields, which
                            <Emphasis variation="bold"> saves you time by auto-filling your agreements with Jobber data like client, quote and job details.</Emphasis>
                        </Text>
                        <div className={styles.nextTitle}>
                            <Heading level={3} >Next Step:</Heading>
                        </div>
                        <Text size="large">
                            <Emphasis variation="bold">We created new custom fields in your Docusign account. </Emphasis>
                            Drag and drop them to your Docusign template where you need data from Jobber auto-filled.
                        </Text>
                        <img src={screenshot} className={`${styles.screenshot}`} alt="" />
                    </div>
                </Content>
            </Modal >
        </>
    );

    function handlePrimaryAction() {
        skipModal(context).then();

        setModalOpen(false);
        window.open(docuSignUrl, "_blank");
    }

    function handleSecondaryAction() {
        skipModal(context).then();

        setModalOpen(false);
    }
}

export default Success;
