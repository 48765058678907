import Title from "components/Title";
import styles from "./styles.module.scss";
import Steps from "components/Steps";
import { Button } from "@jobber/components/Button";
import { useEffect, useState } from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import ErrorBanner from "components/Banners";
import { Grid } from "@jobber/components/Grid";
import { Spinner } from "@jobber/components/Spinner";

function Step1() {
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(function () {
    let state = sessionStorage.getItem('state');

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    if (state) requestHeaders.set('X-INTEGRATION-STATE', state);

    fetch('/api/v1/docusign/redirect_url', { headers: requestHeaders })
      .then(async res => {
        if (res.status === 200) return Promise.resolve(await res.json())
        else return Promise.reject(await res.json())
      })
      .then(data => setRedirectUrl(data.redirect_url))
      .catch(e => ErrorBanner(e.message));
  }, []);

  return (
    <>
      <Title title="Connect to Docusign" subTitle="Let’s get you set up" />
      <div className={styles.description}>
        <Text size="large">
          After connecting your Docusign account and a few settings, your
          integration will be ready to go! Before you continue,{' '}
          <Emphasis variation="bold">make sure you have at least one agreement template created in Docusign.</Emphasis>
        </Text>
      </div>
      <div className={styles.marginAuto}>
        <Grid alignItems="center">
          <Grid.Cell size={{ sm: 12, md: 7, lg: 8 }}>
            <Steps step={1} />
          </Grid.Cell>
          <Grid.Cell size={{ sm: 12, md: 5, lg: 4 }}>
            <a className={styles.textRight} href={redirectUrl}>
              {
                redirectUrl ?
                  <Button label="Connect to Docusign" /> :
                  <div className="center-spinner"><Spinner size="base" aria-label="loading" /></div>
              }
            </a>
          </Grid.Cell>
        </Grid>
      </div>
    </>
  );
}

export default Step1;
