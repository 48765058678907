import { FC } from "react";
import styles from "./styles.module.scss";
import { Text } from "@jobber/components/Text";

type AppSteps = {
    step: number
};

const Steps: FC<AppSteps> = ({ step }) => (<div className={styles.width}>
    <Text size="small">Step {step} of 3</Text>
    <div className={styles.stepWrapper}>
        <span className={`${styles.active} ${styles['step-' + step]}`}></span>
    </div>
</div>
);

export default Steps;
